
import { Component, Vue } from "vue-property-decorator";
import i18n from "../../plugins/i18n";
import Society from "../../models/societymgmt/Society";
import SocietyService from "../../services/societymgmt/SocietyService";
import ToastService from "../../services/helper/ToastService";

@Component
export default class SocietyComponent extends Vue {
  public society: Society = new Society();

  mounted(): void {
    if (
      this.$route.params.id !== undefined &&
      this.$route.params.id !== "new"
    ) {
      SocietyService.get(this.$route.params.id).then((society) => {
        this.society = society;
      });
    }
  }

  save(): void {
    SocietyService.save(this.society).then((item) => {
      ToastService.Success(i18n.tc("labels.saved"), item.name);
      if (item) {
        this.society = item;
        this.handleBack();
      }
    });
  }

  handleBack(): void {
    this.$router.back();
  }
}
